

.bg-slow {
  animation: 2s linear infinite; /* Adjust the duration (10s) as needed */
}

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@200..700&display=swap');

@font-face {
  font-family: 'Gridstar-Regular';
  src: url('/src/assets/Gridstar-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monda-Regular';
  src: url('/src/assets/Monda-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen-Bold';
  src: url('/src/assets/Oxygen-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen-Regular';
  src: url('/src/assets/Oxygen-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IntegralCF-Bold';
  src: url('/src/assets/Fontspring-DEMO-integralcf-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IntegralCF';
  src: url('/src/assets/Fontspring-DEMO-integralcf-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #191918;
  /* background-image: 
  /* url('/src/assets/dark-grain.png'); */
  @apply text-offWhite2
}